import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/SEO/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {
    HeroContainer,
    HeroH1,
    HeroP,
    HeroWrapper,
    HeroWrapperOne
} from "../components/Hero/Hero.styles";
import {Button, Container} from "../components/Layout/Layout.styles";


const NotFoundPage = () => {

    const {t} = useTranslation();

    return (
            <Layout>
                <Seo title={t('404: Not found')}/>
                <div>
                    <HeroContainer/>
                    <Container alignCenter>
                        <HeroWrapper>
                            <HeroWrapperOne>
                                <HeroH1>404: Not Found</HeroH1>
                                <HeroP>You just hit a route that doesn&#39;t exist... the sadness.</HeroP>
                                <Button to="/" big="true" type="submit">
                                    Go back to Homepage
                                </Button>
                            </HeroWrapperOne>
                        </HeroWrapper>
                    </Container>
                </div>
            </Layout>
        )
};

export default NotFoundPage
